@font-face {
  font-family: 'Hind';
  src: url('../../Fonts/Hind-Light.ttf');
}

.newsBlogs {
  display: flex;
  flex-direction: column;
}

.newsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(
    --blog,
    conic-gradient(
      from 111deg at 50% 43.75%,
      rgba(7, 7, 23, 0.5) 6.19787760078907deg,
      rgba(7, 7, 23, 0.5) 17.525568455457687deg,
      rgba(39, 39, 51, 0.5) 108.75000357627869deg,
      rgba(69, 69, 83, 0.5) 172.4999964237213deg,
      rgba(39, 39, 51, 0.5) 236.25deg
    )
  );
}

.h1 {
  display: flex;
  justify-content: center;

  margin-top: 24px;
  margin-bottom: 12px;

  color: #f9d2a2;
  font-size: 29px;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 28.305px;
}

.title {
  margin-top: 16px;

  margin-bottom: 0;
  width: 352px;

  color: #fff;
  font-size: 24px;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 28.305px;
}

.content {
  display: flex;
  width: 340px;
  height: 78px;
  flex-direction: column;

  margin-top: 16px;
  margin-left: 26px;

  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  font-family: Hind;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.moreBtn {
  margin-top: 19px;
  margin-bottom: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 168px;
  height: 48px;
  padding: 10px;
  gap: 10px;
  flex-shrink: 0;

  color: var(--gold, #f9d2a2);
  font-size: 12px;
  font-family: Hind;
  font-weight: 500;
  line-height: 28.305px;
  text-transform: uppercase;

  background-color: transparent;

  border: 2px solid var(--gold, #f9d2a2);
  cursor: pointer;
}

.loadbtn {
  margin-left: 112px;
  margin-top: 19px;
  margin-bottom: 16px;

  display: flex;
  width: 168px;
  height: 48px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  color: #fff;
  font-size: 12px;
  font-family: Hind;
  font-weight: 500;
  line-height: 28.305px;
  text-transform: uppercase;

  background-color: transparent;

  border: 2px solid #fff;
  cursor: pointer;
}

.goldarrow {
  margin-left: 20px;
  background-image: url('../../images/goldarrow.svg');
  width: 18.626px;
  height: 10px;
}

.arrow {
  margin-left: 20px;
  background-image: url('../../images/arrow.svg');
  width: 18.626px;
  height: 10px;
}

.rotates {
  transform: rotate(-90deg);
  transition: 0.5s;
}

.rotatesBack {
  transform: rotate(0deg);
  transition: 0.5s;
}

//pics
.blogImg1 {
  background-image: url('../../images/news1.png');
  width: 390px;
  height: 200px;
}
.blogImg2 {
  background-image: url('../../images/news2.png');
  width: 390px;
  height: 200px;
}
.blogImg3 {
  background-image: url('../../images/news3.png');
  width: 390px;
  height: 200px;
}

.blogImg4 {
  background-image: url('../../images/news4.png');
  width: 390px;
  height: 200px;
}
.blogImg5 {
  background-image: url('../../images/news5.png');
  width: 390px;
  height: 200px;
}
