@font-face {
  font-family: 'Hind';
  src: url('../../Fonts/Hind-Light.ttf');
}

.applyForm {
  margin-top: 60px;
  width: 366px;
  margin-left: 12px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.applyH1 {
  margin-top: -8px;
  margin-bottom: 0;
  color: #f9d2a2;
  font-size: 29px;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 28.305px;
}

.chekcmark {
  display: flex;
  justify-content: center;

  margin-top: 24px;

  color: #fff;
  font-size: 14px;
  font-family: Hind;
  font-weight: 300;
  line-height: 28.305px;
}

.checkbox {
  margin-left: 13px;
  width: 16px;
  height: 16px;
  display: flex;

  &:checked::after {
    fill: #f9d2a2;
    background-image: url('../../images/ic_outline-check.svg');
  }
}

.input-container {
  position: relative;
  display: inline-block;
  width: 366px;
}

.genderBtn-container {
  position: relative;
  width: 366px;
}

.genderBtn {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 24px;
  padding-left: 16px;

  width: 353px;
  height: 48px;

  background-color: white;

  color: rgba(7, 7, 23, 0.7);
  font-size: 15px;
  font-family: Hind;
  font-weight: 300;
  line-height: 130%;

  cursor: pointer;
}

.input {
  margin-top: 24px;
  display: flex;
  align-self: center;
  justify-content: center;
  width: 97%;
  height: 48px;

  color: #070717;
  font-size: 15px;
  font-family: Hind;
  font-weight: 300;
  line-height: 130%;

  border: white solid 3px;
}

.vectorbtn {
  position: absolute;
  right: 15px;
  top: 45%;
  background-image: url('../../images/Vector.svg');

  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  width: 18.626px;
  height: 10px;
  border: none;

  cursor: pointer;
}

.questions {
  margin-top: 24px;
  width: 366px;
  height: 184px;
  background: #fff;
  border: 3px solid transparent;
}

.question1 {
  margin-top: 24px;
  width: 366px;
  height: 208px;
  background: #fff;
  border: 3px solid transparent;
}

.qusteionH1 {
  display: flex;

  margin-left: 16px;

  color: #070717;
  font-size: 15px;
  font-family: Hind;
  font-weight: 300;
  line-height: 130%;
}

.answ {
  display: flex;
  margin-bottom: 14px;

  color: #070717;
  font-size: 15px;
  font-family: Hind;
  font-weight: 300;
  line-height: 130%;
}
.answ1 {
  display: flex;
  margin-top: 16px;
  margin-bottom: 14px;

  color: #070717;
  font-size: 15px;
  font-family: Hind;
  font-weight: 300;
  line-height: 130%;
}

.btnAnsw {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 9px;
  margin-right: 16px;
  z-index: 1;

  width: 16px;
  height: 16px;

  background: #070717;
  border: none;
  cursor: pointer;
}

.btnActive {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #070717;

  background: #f9d2a2;
}

.test {
  margin-top: 17px;
  display: flex;
  width: 320px;
}

.upperinput {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding-left: 15px;
  width: 355px;
  height: 23px;

  background: #fff;
  margin-top: 24px;

  color: #070717;
  font-family: Hind;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
}

.howInput {
  display: flex;
  align-self: center;
  justify-content: center;
  width: 97%;
  height: 40px;
  margin-top: 24px;

  color: #070717;
  font-size: 15px;
  font-family: Hind;
  font-weight: 300;
  line-height: 130%;

  border: white solid 3px;
}

.submit {
  display: flex;

  margin-top: 24px;

  color: #f9d2a2;
  font-size: 12px;
  font-family: Hind;
  font-weight: 500;
  line-height: 28.305px;
  text-transform: uppercase;

  width: 366px;
  height: 48px;
  padding: 10px 0px 7px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border: 1px solid #f9d2a2;
  cursor: pointer;

  background: transparent
    radial-gradient(circle, transparent 100%, #070717 100%);
}

.submit:focus {
  color: #070717;
  background-color: #f9d2a2;
  background-size: 100%;
  transition: background 3s;
}

.input-error {
  border: 3px solid red;
}

.input2 {
  margin-top: 10px;
  display: flex;
  align-self: center;
  justify-content: center;
  width: 99%;
  height: 48px;

  // color: #070717;
  color: #f9d2a2;
  font-size: 20px;
  font-family: Hind;
  font-weight: 300;
  line-height: 130%;
}
