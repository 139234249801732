.social {
  margin-left: 15px;
}
.socialH1 {
  display: flex;
  justify-content: center;

  color: #f9d2a2;
  font-size: 29px;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 28.305px;
}

.socialTxt {
  margin-top: 24px;
  width: 344px;

  color: #fff;

  font-size: 15px;
  font-family: Hind;
  font-weight: 300;
  line-height: 150%;
}

.igPhotos {
  margin-top: 40px;
  margin-bottom: 24px;
}

.igPhoto {
  width: 361px;
  height: 365px;
}

.socialsBtn {
  display: flex;
  width: 366px;
  height: 48px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  color: #fff;
  font-size: 12px;
  font-family: Hind;
  font-weight: 500;
  line-height: 28.305px;
  text-transform: uppercase;

  background-color: transparent;

  border: 2px solid #fff;
  cursor: pointer;
}
