.videoRoll {
  display: flex;

  margin-top: 24px;
  margin-left: 12px;
  margin-bottom: 24px;
  width: 360px;
  height: 646px;
}

.videoBtnBlock {
  display: flex;
  flex-direction: row;
  margin-left: 158px;
  margin-bottom: 14px;
}
