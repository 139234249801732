.container {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header {
  width: 390px;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  background: rgba(7, 7, 23, 0.5);
  backdrop-filter: blur(3px);
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
  width: 60px;
  height: 56px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.line1,
.line2 {
  width: 25px;
  height: 3px;
  margin-bottom: 8px;
  transition: transform 0.3s, opacity 0.3s;
}
.line3 {
  width: 25px;
  height: 3px;
  transition: transform 0.3s, opacity 0.3s;
}

.line1 {
  background-image: url('../images/Line\ 1.svg');
}

.line2 {
  background-image: url('../images/Line\ 1.svg');
}

.line3 {
  background-image: url('../images/Line\ 1.svg');
}

.menu.active .line1 {
  transform: rotate(45deg) translate(6.5px, 6.5px);
  margin-bottom: 5.2px;
}

.menu.active .line2 {
  opacity: 0;
}

.menu.active .line3 {
  transform: rotate(-45deg) translate(6.5px, -6.5px);
}

.logobox {
  display: flex;
  flex-direction: column;
  margin-left: 100px;

  border: none;
  background-color: transparent;
  cursor: pointer;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -18px;
  width: 75px;
  height: 31px;

  background-repeat: no-repeat;
  background-image: url('../images/duvalLogo.svg');
}

.bottom {
  display: flex;
  flex-direction: column;
}

.rectangle {
  width: 390px;
  height: 2px;
  background-color: #f9d2a2;
}
