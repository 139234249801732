.photoRoll {
  display: flex;

  margin-top: 16px;

  width: 390px;
  height: 390px;
}

.photo1 {
  position: absolute;
  width: 390px;
  height: 390px;
  background-image: url('../images/model1.png');
  mix-blend-mode: luminosity;
  z-index: 1;
  opacity: 0.8;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.photoText1 {
  margin-top: 14px;
  margin-left: 16px;
  margin-right: 11px;

  font-family: 'Hind';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 150%;

  color: #ffffff;
}

.photo2 {
  position: absolute;
  width: 390px;
  height: 390px;
  background-image: url('../images/model2.png');
  mix-blend-mode: luminosity;
  z-index: 1;
  opacity: 0.8;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.photoH {
  width: 366px;
  height: 36px;
  margin-left: 16px;
  margin-top: 16px;

  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;

  color: #ffffff;
}

.photoText2 {
  margin-top: 14px;
  margin-left: 20px;
  margin-right: 4px;

  font-family: 'Hind';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 150%;

  color: #ffffff;
}

.photo3 {
  position: absolute;
  width: 390px;
  height: 390px;
  background-image: url('../images/model3.png');
  mix-blend-mode: luminosity;
  z-index: 1;
  opacity: 0.8;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.photo4 {
  position: absolute;
  width: 390px;
  height: 390px;
  background-image: url('../images/Group\ 10.png');
  mix-blend-mode: luminosity;
  z-index: 1;
  opacity: 0.8;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.photo5 {
  position: absolute;
  width: 390px;
  height: 390px;
  background-image: url('../images/Group\ 11.png');
  mix-blend-mode: luminosity;
  z-index: 1;
  opacity: 0.8;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.photo6 {
  position: absolute;
  width: 390px;
  height: 390px;
  background-image: url('../images/Group\ 12.png');
  mix-blend-mode: luminosity;
  z-index: 1;
  opacity: 0.8;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.btnBlock {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-left: 158px;
  margin-bottom: 14px;
}

.teamBtn {
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: 2;

  border: 2px solid #ffffff;
  background-color: transparent;
  cursor: pointer;
}

.teamBtn2 {
  position: absolute;
  width: 16px;
  height: 16px;

  z-index: 2;
  border: 2px solid #ffffff;
  background-color: transparent;
  margin-left: 37px;
  cursor: pointer;
}

.teamBtn3 {
  position: absolute;
  width: 16px;
  height: 16px;
  margin-left: 76px;

  z-index: 2;

  border: 2px solid #ffffff;
  background-color: transparent;
  cursor: pointer;
}

.activebtn {
  position: absolute;
  width: 18px;
  height: 18px;

  border: 2px solid #f9d2a2;
}
