iframe {
  display: none;
}

body {
  margin: 0;
}

html {
  background-color: #070717;
}
