.readMore {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  &.expd {
    animation: max-height 3s expd ease-in;
  }
}

@keyframes expd {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.title1 {
  display: flex;
  width: 339px;

  margin-top: 32px;
  margin-bottom: 24px;

  color: #fff;
  font-family: Hind;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.text1 {
  width: 339px;

  margin-bottom: 16px;

  color: #fff;
  font-family: Hind;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.text1-1 {
  width: 339px;

  color: #fff;
  font-family: Hind;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.text1-2 {
  width: 339px;

  margin-top: 16px;
  margin-bottom: 16px;

  color: #fff;
  font-family: Hind;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.title2 {
  display: flex;
  width: 339px;

  margin-top: 32px;
  margin-bottom: 24px;

  color: #fff;
  font-family: Hind;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.title3 {
  display: flex;
  width: 339px;

  margin-top: 32px;
  margin-bottom: 24px;

  color: #fff;
  font-family: Hind;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.title4 {
  display: flex;
  width: 339px;

  margin-bottom: 24px;

  color: #fff;
  font-family: Hind;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
