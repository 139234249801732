@font-face {
  font-family: 'Playfair Display';
  src: url('../../Fonts/PlayfairDisplay-Regular.ttf');
}
@font-face {
  font-family: 'Hind';
  src: url('../../Fonts/Hind-Light.ttf');
}

.aboutH1 {
  display: flex;
  justify-content: center;
  margin-top: 44px;

  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 500;
  font-size: 29px;
  line-height: 28px;

  text-transform: uppercase;
  color: #f9d2a2;
}

.aboutText {
  margin-left: 14px;
  margin-right: 11px;
  margin-top: 19px;

  font-family: 'Hind';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 148%;

  color: #ffffff;
}

.teamBg {
  position: absolute;
  background-image: url('../../images/teamBg.png');
  width: 364px;
  height: 364px;
  margin-top: -20px;
  margin-left: 28px;
  z-index: 1;

  mask-image: url('../../images/teamBg.png');
}

.aboutH4 {
  margin-left: 13px;
  margin-right: 11px;
  margin-top: 24px;

  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;

  color: #f9d2a2;
}

.aboutH2 {
  margin-left: 12px;
  margin-top: 32px;

  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  z-index: 2;
}

.aboutText2 {
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 16px;

  font-family: 'Hind';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 150%;
  z-index: 2;

  color: #ffffff;
}

.aboutH3 {
  margin-left: 12px;
  margin-top: 20px;
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;

  color: #ffffff;
}

.welcome {
  color: #fff;
  text-align: center;
  font-size: 29px;
  font-family: Playfair Display;
  line-height: 130%;
}
