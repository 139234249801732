.genders {
  display: flex;
  flex-direction: column;

  justify-content: center;
  margin-top: 24px;
  width: 368px;
  height: 100px;
  background: #fff;
  border: white solid 3px;
}

.input-error {
  border: 3px solid red;
}

.field {
  display: flex;
  flex-direction: row;
}

.btnGenders {
  margin-bottom: 12px;
  display: flex;

  margin-left: 14px;
  width: 16px;
  height: 16px;
  background: #070717;

  border: none;
}

.gender {
  display: flex;
  margin-left: 21px;

  color: #070717;
  font-size: 15px;
  font-family: Hind;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
}
.btnactive {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #070717;

  background: #f9d2a2;
}
