.privacy {
  display: flex;
  // width: 220px;
  height: 290px;

  margin-top: 24px;
}

.leftPt {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  // margin-right: 16px;
}

.logoYellow {
  display: flex;
  flex-shrink: 0;

  width: 75px;
  height: 34.623px;

  background-image: url('../../images/duvalLogo.svg');

  background-repeat: no-repeat;
}

.location {
  display: flex;
  width: 80px;
  flex-direction: column;
  flex-shrink: 0;

  color: #f9d2a2;
  font-size: 8.656px;
  font-family: Hind;
  font-weight: 500;
}

.middle1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 11px;
}

.privacyH {
  display: flex;

  color: #f9d2a2;
  text-align: center;
  font-size: 14px;
  font-family: Hind;
  font-weight: 600;
  line-height: 150%;

  border: none;
  background: none;
  cursor: pointer;
}

.navig {
  width: 100px;
  margin-top: 6px;

  color: #f9d2a2;
  text-align: center;
  font-size: 12px;
  font-family: Hind;
  font-weight: 300;
  line-height: 150%;

  border: none;
  background: none;
  cursor: pointer;
}

.navig1 {
  width: 120px;
  margin-top: 6px;

  color: #f9d2a2;
  text-align: center;
  font-size: 12px;
  font-family: Hind;
  font-weight: 300;
  line-height: 150%;

  border: none;
  background: none;
  cursor: pointer;
}

.navigSvg1 {
  margin-top: 17px;

  background-image: url('../../images/igtest.png');
  width: 24px;
  height: 24px;

  background-color: #070717;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
}
.navigSvg2 {
  margin-top: 12px;

  background-image: url('../../images/ri_twitter-line.svg');
  width: 24px;
  height: 24px;

  background-color: #070717;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
}

.footer {
  margin-top: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #ffffff;
}

.fooTxt {
  color: #070717;
  text-align: center;
  font-size: 12px;
  font-family: Hind;
  font-weight: 300;
  line-height: 150%;
}

.input2 {
}
