.forModels {
  display: flex;
  flex-direction: column;
}

.model1 {
  position: absolute;
  margin-top: -99px;
  background-image: url('../../images/model1.png');
  width: 390px;
  mix-blend-mode: luminosity;
  height: 300px;
  z-index: 1;
}

.modelH1 {
  margin-left: 12px;
  margin-top: 16px;

  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 120%;
  z-index: 2;
  color: #f9d2a2;
}

.modelbtn {
  width: 168px;
  height: 48px;
  margin-left: 24px;

  font-family: 'Hind';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;

  color: #ffffff;
  background-color: transparent;
  border: 2px solid white;
  z-index: 2;

  cursor: pointer;
}

.modelH2 {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-size: 29px;
  line-height: 28px;

  margin-top: 40px;
  margin-left: 12px;

  color: #ffffff;
}

.modelText {
  margin-left: 12px;
  margin-top: 16px;
  margin-right: 12px;
  font-family: 'Hind';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 150%;

  color: #ffffff;
}
