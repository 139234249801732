.submitted {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 24px;
  width: 366px;
  height: 200px;

  background: #f9d2a2;
}

.succes {
  margin-top: 32px;
  display: flex;
  width: 301px;
  height: 29px;
  flex-direction: column;
  flex-shrink: 0;

  color: #070717;
  text-align: center;
  font-size: 29px;
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 500;
  line-height: 28.305px;
}

.willReview {
  margin-top: 20px;

  display: flex;
  width: 307px;
  height: 38px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  color: #070717;
  text-align: center;
  font-size: 15px;
  font-family: Hind;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
}

.forInfo {
  margin-top: 16px;

  display: flex;
  width: 307px;
  height: 38px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  color: rgba(7, 7, 23, 0.5);
  text-align: center;
  font-size: 13px;
  font-family: Hind;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
}
