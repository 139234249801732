@font-face {
  font-family: 'Hind';
  src: url('../Fonts/Hind-Light.ttf');
}

.navigation {
  width: 390px;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;

  top: 58px;
  z-index: 1000;
  background-color: #070717;

  background: rgba(7, 7, 23, 0.5);
  backdrop-filter: blur(5.5px);

  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  &.open {
    opacity: 1;
    transform: translateY(0);
  }
}

.btn {
  margin-bottom: 16px;

  font-family: 'Hind';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;

  border: none;
  background-color: transparent;

  color: #f9d2a2;
  cursor: pointer;
  text-decoration: none;
}

.navbtn {
  margin-bottom: 16px;

  font-family: 'Hind';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;

  display: flex;
  align-items: center;
  text-align: center;

  border: none;
  background-color: transparent;

  color: #f2f2f2;
  cursor: pointer;
  text-decoration: none;
}

.btnManager {
  margin-bottom: 10px;

  font-family: 'Hind';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;

  border: none;
  background-color: transparent;

  color: #f9d2a2;
  cursor: pointer;
  text-decoration: none;
}

.navbtn1 {
  margin-bottom: 16px;

  font-family: 'Hind';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;

  display: flex;
  align-items: center;
  text-align: center;

  border: none;
  background-color: transparent;

  color: #f2f2f2;
  cursor: pointer;
  text-decoration: none;
}

.bottomNav {
  display: flex;
  flex-direction: column;
}

.rectangleNav {
  position: absolute;
  width: 390px;
  height: 2px;
  background-color: #f9d2a2;
}
