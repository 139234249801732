.faq {
  margin-left: 11px;
  margin-right: 14px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.faqH1 {
  margin-bottom: 24px;
  margin-top: 8px;

  display: flex;
  width: 387px;
  height: 57px;
  flex-direction: column;

  color: #f9d2a2;
  text-align: center;
  font-size: 29px;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 28.305px;
}

.faqBtn {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 24px;

  color: #fff;
  text-align: center;
  font-size: 12px;
  font-family: Hind;
  line-height: 150%;

  width: 366px;
  height: 48px;

  background: transparent;
  border: 2px solid #fff;
}

.text {
  display: flex;
  align-items: flex-start;
  width: 300px;
}

.arrow {
  margin-left: 20px;
  background-image: url('../../images/arrow.svg');
  width: 18.626px;
  height: 10px;

  border: transparent;
  background-color: transparent;
  cursor: pointer;
}

.rotates {
  transform: rotate(-90deg);
  transition: 0.5s;
}

.rotatesBack {
  transform: rotate(0deg);
  transition: 0.5s;
}

.anstext {
  display: flex;
  margin-left: 29px;
  margin-bottom: 24px;
  margin-top: -8px;

  width: 333px;
  flex-direction: column;
  flex-shrink: 0;

  color: #fff;
  font-family: Hind;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  &.expanded {
    animation: 1s expanded ease-out;
  }
}

@keyframes expanded {
  0% {
    opacity: 0;
    transform: translateY(-80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
