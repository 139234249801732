@font-face {
  font-family: 'FutuHv';
  src: url('../Fonts/FuturaHeavyBT.ttf');
}
@font-face {
  font-family: 'Futura Md BT';
  src: url('../Fonts/FuturaMediumBT.ttf');
}

.courseApp {
  width: 390px;
}

.headerbox {
  height: 162px;
  font-family: 'FutuHv';
  font-style: normal;
  font-size: 40px;
  color: #f9d2a2;
  margin-left: 15px;
}

.coursebtn {
  margin-left: 15px;
  width: 175px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9d2a2;
  border: none;

  font-family: 'FutuHv';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #070717;

  cursor: pointer;
}

.infobox {
  margin-left: 15px;
  margin-top: 49px;
}

.nobodyTell {
  font-family: 'FutuHv';
  font-style: normal;
  font-weight: 400;
  font-size: 29px;
  color: #ffffff;

  width: 295px;
  height: 56px;
}

.info1 {
  margin-top: 16px;
  font-family: 'Futura Md BT';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  color: #ffffff;
}

.info2 {
  margin-top: 15px;
  font-family: 'Futura Md BT';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  color: #ffffff;
}

.h2 {
  margin-top: 28px;
  font-family: 'FutuHv';
  font-style: normal;
  font-weight: 400;
  font-size: 29px;
  line-height: 28px;

  color: #ffffff;
}

.info3 {
  margin-top: 8px;
  font-family: 'Futura Md BT';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  color: #ffffff;
}
